import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import ModalPortal from '@common/ModalPortal';
import ApplyFormTop from '@modal/ApplyForm/ApplyFormTop';
import ApplyFormMiddle from '@modal/ApplyForm/ApplyFormMiddle';
import ApplyFormBottom from '@modal/ApplyForm/ApplyFormBottom';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

interface IBackdrop {
  isOpen: boolean;
}
export default function ApplyForm({ isOpen, setIsOpen }: Props): JSX.Element {
  // const backDropOnClick = () => {
  //   setIsOpen(prev => !prev);
  //   document.body.style.overflow = 'unset';
  // };

  // const containerOnClick = (event: React.MouseEvent) => {
  //   event.stopPropagation();
  // };

  return (
    <ApplyModalPortal>
      {/*<Backdrop onClick={backDropOnClick} isOpen={isOpen}>*/}
      {/*  <FormContainer onClick={event => containerOnClick(event)}>*/}
      <Backdrop isOpen={isOpen}>
        <FormContainer>
          <ApplyFormTop setIsOpen={setIsOpen} />
          <ApplyFormMiddle />
        </FormContainer>
      </Backdrop>
    </ApplyModalPortal>
  );
}
const ApplyModalPortal = styled(ModalPortal)`
  overflow: auto;
`;

const Backdrop = styled.div<IBackdrop>`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 110;
  position: relative;
  background: #fff;
  width: 50%;
  height: 90%;
  border-radius: 20px;
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
