import React, { useState } from 'react';

import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import LOGO_IMG from '@svgs/header_logo.svg';
import { HEADER_HEIGHT } from '@const/index';

const NAV_BTN_ARR = ['공지사항', '소개서 다운로드', '입점 문의', '스토어 어드민 페이지'];

interface IHeaderWrapper {
  headerHeight: string;
}

interface IHeaderProp {
  onClick: () => void;
  hamburgerOnClick: () => void;
}

interface INavProp {
  onClick: () => void;
}
export default function Header({ onClick, hamburgerOnClick }: IHeaderProp) {
  const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   if (window.innerWidth <= 600) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // }, []);

  return (
    <HeaderWrapper headerHeight={HEADER_HEIGHT}>
      <Link to="/">
        <HeaderLogo src={LOGO_IMG} />
      </Link>
      <NavButtonsComponent onClick={onClick} />
      <Hamburger onClick={hamburgerOnClick}> 햄버거</Hamburger>
    </HeaderWrapper>
  );
}

function NavButtonsComponent({ onClick }: INavProp) {
  const navigate = useNavigate();
  const navOnClickHandler = (index: number) => {
    switch (index) {
      case 0:
        return navigate('notice');
      case 1:
        break;
      case 2:
        return onClick();
      case 3:
        break;
    }
  };
  return (
    <NavButtons>
      {NAV_BTN_ARR.map((item, index) => {
        return (
          <NavButton onClick={() => navOnClickHandler(index)} key={`header_nav_${index}`}>
            {item}
          </NavButton>
        );
      })}
    </NavButtons>
  );
}

const Hamburger = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
    margin-right: 5vw;
  }
`;

const HeaderWrapper = styled.header<IHeaderWrapper>`
  //padding: 3vh 2vw;
  width: 100%;
  height: ${({ headerHeight }) => headerHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  background: #fff;
`;
const HeaderLogo = styled.img`
  width: 240px;
  margin-left: 5vw;

  @media (max-width: 600px) {
    width: 160px;
  }
`;
const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 0.9em;
  font-weight: 700;
  width: 40%;
  margin-right: 5vw;

  @media (max-width: 600px) {
    display: none;
  }
`;

const NavButton = styled.div`
  //border: 1px solid black;
  cursor: pointer;
`;
