import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { HEADER_HEIGHT } from '@const/index';
import { IWrapper } from '@pages/NoticeDetail';
import AdjustCenter from '@common/AdjustCenter';

interface IChildProps {
  children: ReactNode;
  title: string;
  count: number;
}

export default function ListPageTemplate({ children, title, count }: IChildProps): JSX.Element {
  return (
    <Wrapper headerHeight={HEADER_HEIGHT}>
      <Banner />
      <AdjustCenter>
        <NoticeListTop>
          <NoticeListTitle>{title}</NoticeListTitle>
          <Count>TOTAL: {count}</Count>
        </NoticeListTop>
      </AdjustCenter>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<IWrapper>`
  margin-top: ${({ headerHeight }) => headerHeight};
`;

const Banner = styled.div`
  width: 100%;
  height: 400px;
  background: #fbaf14;
`;

const NoticeListTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10vh 0;
  padding: 0 2vw;

  @media (max-width: 600px) {
    margin: 5vh 0;
  }
`;

const NoticeListTitle = styled.div`
  border-left: 5px solid #fbaf14;
  padding-left: 1vw;
  font-family: 'GmarketSansMedium', sans-serif;
  font-weight: 700;
  font-size: 2em;

  @media (max-width: 600px) {
    font-size: 1.8em;
    padding-left: 3vw;
  }
`;
const Count = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;
