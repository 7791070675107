import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Main from '@pages/Main';
import Info from '@pages/Info';
import Notice from '@pages/Notice';
import Header from '@components/Header';
import Footer from '@components/Footer';
import ApplyForm from '@modal/ApplyForm';
import GlobalStyle from '@common/GlobalStyle';
import ScrollToTop from '@common/ScrollToTop';
import NoticeDetail from '@pages/NoticeDetail';
import HamburgerModal from '@modal/HamburgerModal';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const onClick = () => {
    setIsOpen((prev: boolean) => !prev);
    document.body.style.overflow = 'hidden';
  };
  const hamburgerOnClick = () => {
    setIsHamburgerOpen((prev: boolean) => !prev);
    document.body.style.overflow = 'hidden';
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <GlobalStyle />
      <Header onClick={onClick} hamburgerOnClick={hamburgerOnClick} />
      <Routes>
        <Route element={<Main isOpen={isOpen} onClick={onClick} />} path="/" />
        <Route element={<Notice />} path="/notice" />
        <Route element={<NoticeDetail />} path="/notice/:id" />
        <Route element={<Info />} path="/information" />
      </Routes>
      <Footer />
      {isHamburgerOpen && (
        <HamburgerModal
          isHamburgerOpen={isHamburgerOpen}
          setIsHamburgerOpen={setIsHamburgerOpen}
          hamburgerOnClick={hamburgerOnClick}
          onClick={onClick}
        />
      )}
      {isOpen && <ApplyForm isOpen={isOpen} setIsOpen={setIsOpen} />}
    </BrowserRouter>
  );
}

export default App;
