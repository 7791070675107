import styled from 'styled-components';

interface Props {
  data: string[];
  textAlign?: string;
}

interface TextWrapperProps {
  textAlign: string;
}

export default function PutNewLine({ data, textAlign = 'left' }: Props) {
  return <TextWrapper textAlign={textAlign}>{data.map(text => text).join('\n')}</TextWrapper>;
}

const TextWrapper = styled.div<TextWrapperProps>`
  white-space: pre-line;
  text-align: ${({ textAlign }) => textAlign};
`;
