import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AdjustCenter from '@common/AdjustCenter';

const MAIN_INFO_TEXT = {
  title: '오늘의 업체 소식',
  more: '더 보기',
};

const DUMMY_DATA = [
  { id: 1, title: '제목스1', subtitle: '내용스1' },
  { id: 2, title: '제목스2', subtitle: '내용스2' },
  { id: 3, title: '제목스3', subtitle: '내용스3' },
  { id: 4, title: '제목스4', subtitle: '내용스4' },
  { id: 5, title: '제목스5', subtitle: '내용스5' },
  { id: 6, title: '제목스6', subtitle: '내용스6' },
  { id: 7, title: '제목스7', subtitle: '내용스7' },
  { id: 8, title: '제목스8', subtitle: '내용스8' },
];
export default function MainInfo(): JSX.Element {
  const navigate = useNavigate();
  return (
    <AdjustCenter>
      <Wrapper>
        <TopContainer>
          <Title>{MAIN_INFO_TEXT.title}</Title>
          <More onClick={() => navigate('information')}>{MAIN_INFO_TEXT.more}</More>
        </TopContainer>
        <Swiper
          slidesPerView={3.6}
          loop={true}
          speed={1200}
          spaceBetween={20}
          modules={[Autoplay]}
          autoplay={{ delay: 4000, disableOnInteraction: true }}
          breakpoints={{
            701: { slidesPerView: 3.6 },
            0: { slidesPerView: 1.3 },
          }}
        >
          {DUMMY_DATA.map((arr, index) => {
            return (
              <CustomSwiperSlide key={`news_card_${index}`}>
                <ItemImage />
                <ItemTitle>{arr.title}</ItemTitle>
                <ItemSubTitle>{arr.subtitle}</ItemSubTitle>
              </CustomSwiperSlide>
            );
          })}
        </Swiper>
      </Wrapper>
    </AdjustCenter>
  );
}

const Wrapper = styled.div`
  margin: 10vh 0;
`;

const CustomSwiperSlide = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
`;
const Title = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 2em;
  font-weight: 700;
`;
const More = styled.div`
  cursor: pointer;
`;
const CarouselContainer = styled.div`
  width: 100%;
`;
const ItemImage = styled.div`
  width: 100%;
  height: 270px;
  background: #eee;
`;
const ItemTitle = styled.div`
  width: 100%;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 1.2em;
  text-align: left;
  padding-left: 1vw;
  margin: 2vh 0;
`;
const ItemSubTitle = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 1vw;
`;
