import React from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const DUMMY_NOTICES = [
  {
    type: '공지',
    title:
      '사장님 인스타 페이지 개설asdfasdfasdfasdfasdfasdfasdfasasdfasdfasdfadsfasdfasdfasdfasdfasdfasdfasdfasdfasd',
    createdAt: 'YYYY.MM.DD',
  },
  {
    type: '공지',
    title: '사장님 앱 업데이트',
    createdAt: 'YYYY.MM.DD',
  },
  {
    type: '공지',
    title: '사장님 앱 출시 예정',
    createdAt: 'YYYY.MM.DD',
  },
  {
    type: '공지',
    title: '사장님 앱 이용 방법',
    createdAt: 'YYYY.MM.DD',
  },
  {
    type: '공지',
    title: '사장님 앱앱',
    createdAt: 'YYYY.MM.DD',
  },
];
export default function MainNotice(): JSX.Element {
  const navigate = useNavigate();

  const moreButtonOnClick = () => {
    navigate('notice');
  };

  return (
    <MainNoticeWrap>
      <MainNoticeContainer>
        <LeftSide>
          <LeftSideTop>
            <Title>공지사항</Title>
            <More onClick={moreButtonOnClick}>더 보기</More>
          </LeftSideTop>
          <TitleDivider />
          <NoticeListContainer>
            {DUMMY_NOTICES.map((notice, index) => {
              return (
                <div key={`notice_${index}`}>
                  <NoticeItemContainer>
                    <NoticeType>{notice.type}</NoticeType>
                    <NoticeTitle>{notice.title}</NoticeTitle>
                    <NoticeCreatedAt>{notice.createdAt}</NoticeCreatedAt>
                  </NoticeItemContainer>
                  {DUMMY_NOTICES.length - 1 !== index && <Divider />}
                </div>
              );
            })}
          </NoticeListContainer>
          <TitleDivider />
        </LeftSide>
        <RightSide>
          <ImageBox>이미지</ImageBox>
        </RightSide>
      </MainNoticeContainer>
    </MainNoticeWrap>
  );
}

const MainNoticeWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vh 0;
`;
const MainNoticeContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
`;
const LeftSide = styled.div`
  width: 65%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const LeftSideTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
`;
const More = styled.div`
  cursor: pointer;
`;
const NoticeListContainer = styled.div``;
const Title = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 2em;
`;
const TitleDivider = styled.div`
  border: 0.5px solid #fbaf17;
  margin: 1.5vh 0;
`;
const NoticeItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5vh;
`;
const NoticeType = styled.div`
  width: 10%;
  margin: 0 1vw;
  text-align: center;

  @media (max-width: 600px) {
    width: 20%;
  }
`;
const NoticeTitle = styled.div`
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 600px) {
    width: 80%;
  }
`;
const NoticeCreatedAt = styled.div`
  width: 20%;
  text-align: center;

  @media (max-width: 600px) {
    display: none;
  }
`;
const Divider = styled.div`
  border: 0.5px dashed #bbb;
  margin-top: 1.5vh;
`;

const RightSide = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;
const ImageBox = styled.div``;
