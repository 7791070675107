import { Dispatch, useState } from 'react';

import styled from 'styled-components';

interface IProps {
  LIMIT_PER_PAGE: number;
  OFFSET: number;
  TOTAL: number;
  page: number;
  setPage: Dispatch<number>;
}
export default function Pagination({ LIMIT_PER_PAGE, OFFSET, TOTAL, page, setPage }: IProps) {
  const [checkPoint, setCheckPoint] = useState({ start: 1, end: OFFSET });
  const PAGE_COUNT = Math.ceil(TOTAL / LIMIT_PER_PAGE);
  const PAGINATION_ARR = Array(PAGE_COUNT)
    .fill(null)
    .map((_, i) => i + 1);

  const prevButtonHandler = () => {
    if (page % OFFSET === 1) {
      setCheckPoint(prev => {
        return {
          start: prev.start - OFFSET,
          end: prev.end - OFFSET,
        };
      });
    }
    setPage(page - 1);
  };
  const nextButtonHandler = () => {
    if (page % OFFSET === 0) {
      setCheckPoint(prev => {
        return {
          start: prev.start + OFFSET,
          end: prev.end + OFFSET,
        };
      });
    }
    setPage(page + 1);
  };

  const buttonOnClick = (item: number) => {
    setPage(item);
  };

  return (
    <Nav>
      <Button onClick={prevButtonHandler} disabled={page === 1}>
        &lt;
      </Button>
      {PAGINATION_ARR.slice(checkPoint.start - 1, checkPoint.end).map((item, index) => (
        <Button
          key={index + 1}
          onClick={() => buttonOnClick(item)}
          aria-current={page === item ? 'page' : undefined}
        >
          {item}
        </Button>
      ))}
      <Button onClick={nextButtonHandler} disabled={page === PAGE_COUNT}>
        &gt;
      </Button>
    </Nav>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
`;

const Button = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  //padding: 8px;
  margin: 0 1vw;
  border-radius: 100%;
  background: white;
  //margin: 0;
  color: #000;
  font-size: 1rem;

  &:hover {
    background: #fbaf14;
    cursor: pointer;
  }

  &[disabled] {
    //background: grey;
    cursor: revert;
    transform: revert;
  }

  &[aria-current] {
    background: #fbaf14;
    //font-weight: bold;
    cursor: revert;
    transform: revert;
  }
`;
