import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
 
  body { 
    width: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  button{
    color: #000;
    cursor: pointer;
  }

  input, textarea {
    appearance: none;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    -moz-border-radius: 0;
    
    ::placeholder{
      color: #c0c0c0;
    }
  }

  select{
    /*ios대응*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ///*화살표 배경 넣기*/
    //background: SELECT_BOX_ARROW no-repeat 98% 50% #fff; /*화살표 select박스 오른쪽 중앙 배치,배경 흰색*/
  }
  select::-ms-expand{
    display:none; /* 화살표 없애기 for IE10, 11*/
  }
`;
