import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Controller, FieldErrors, FieldValue, SubmitHandler, useForm } from 'react-hook-form';

import CHECKED from '@svgs/checked.svg';
import NOT_CHECKED from '@svgs/not_checked.svg';
import Select, { CSSObjectWithLabel } from 'react-select';
import { COMPANY_DETAIL_ADDRESS } from '@const/index';

const FORM_MID_TEXT = {
  company: [
    {
      title: '업체 유형',
      placeHolder: '업체 유형을 선택해주세요',
      value: 'companyType',
    },
    {
      title: '업체 이름',
      placeHolder: '업체 이름을 입력해주세요',
      value: 'companyName',
    },
    {
      title: '업체 주소',
      placeHolder: '지역을 선택해주세요',
      value: 'companyAddress',
    },
    {
      title: '업체 상세 주소',
      placeHolder: '상세 지역을 선택해주세요',
      value: 'companyDetailAddress',
    },
  ],
  inCharge: [
    {
      title: '담당자 성함',
      placeHolder: '연락받으실 담당자 성함을 입력해주세요',
      value: 'name',
    },
    {
      title: '담당자 연락처',
      placeHolder: '연락받으실 담당자 연락처를 입력해주세요',
      value: 'phone',
    },
    {
      title: '담당자 이메일',
      placeHolder: '연락받으실 담당자 이메일을 입력해주세요',
      value: 'email',
    },
  ],
};

const COMPANY_TYPE = [
  { value: '상품', label: '상품' },
  { value: '숙박', label: '숙박' },
  { value: '티켓', label: '티켓' },
  { value: '광고', label: '광고' },
  { value: '기타', label: '기타' },
];

const FORM_BOT_TEXT = {
  title: '[필수] 개인정보 수집 및 활용에 동의합니다.',
  chkOn: '',
  chkOff: '',
  btnText: '입점 신청하기',
};

// interface IAddress {
//   [key: string]: { value: string; label: string }[];
// }
const COMPANY_ADDRESS = [
  { value: '서울특별시', label: '서울특별시' },
  { value: '부산광역시', label: '부산광역시' },
  { value: '대구광역시', label: '대구광역시' },
  { value: '인천광역시', label: '인천광역시' },
  { value: '광주광역시', label: '광주광역시' },
  { value: '대전광역시', label: '대전광역시' },
  { value: '울산광역시', label: '울산광역시' },
  { value: '세종특별자치시', label: '세종특별자치시' },
  { value: '경기도', label: '경기도' },
  { value: '강원도', label: '강원도' },
  { value: '충청북도', label: '충청북도' },
  { value: '충청남도', label: '충청남도' },
  { value: '전라북도', label: '전라북도' },
  { value: '전라남도', label: '전라남도' },
  { value: '경상북도', label: '경상북도' },
  { value: '경상남도', label: '경상남도' },
  { value: '제주특별자치도', label: '제주특별자치도' },
];

// interface IFormInput {
//   // companyType: string;
//   // companyName: string;
//   // companyAddress: string;
//   // companyDetailAddress: string;
//   // name: string;
//   // phone: string;
//   // email: string;
// }
// type TErrors = {
//   error: FieldErrors<FieldValue<any>>;
// };
// interface IFormState {
//   formState: TErrors;
//   companyDetailAddress: string;
// }

export default function ApplyFormMiddle() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const [selectedAddress, setSelectedAddress] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const onSubmit = data => {
    if (!isChecked) {
      console.log('동의해주셈');
    } else {
      console.log(data);
    }
  };

  const onChangeHandler = event => {
    setSelectedAddress(event.target.value);
  };

  const checkBoxOnClick = () => {
    setIsChecked(prev => !prev);
  };

  useEffect(() => {
    reset({ companyDetailAddress: '' });
    // setSelectedAddress('');
  }, [selectedAddress]);

  const selectBoxStyle = {
    menuList: base => ({
      ...base,
      padding: 0,
      marginTop: -20,
      borderRadius: 4,
    }),
    option: base => ({
      ...base,
      background: '#efefef',
      color: '#000000',
      fontSize: '0.9em',
    }),
    control: base => ({
      ...base,
      background: '#efefef',
      fontSize: '0.9em',
      marginBottom: '2vh',
      border: 0,
      // This line disable the blue border
      boxShadow: 'none',
      height: 50,
    }),
    placeholder: base => ({
      ...base,
      color: '#c0c0c0',
    }),
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <FormLabel>{FORM_MID_TEXT.company[0].title}</FormLabel>
        <Controller
          name="companyType"
          render={({ field }) => (
            <Select
              {...field}
              placeholder={FORM_MID_TEXT.company[0].placeHolder}
              options={COMPANY_TYPE}
              isSearchable={false}
              styles={selectBoxStyle}
            />
          )}
          control={control}
          rules={{ required: { value: true, message: '업체 유형을 선택해주세요!' } }}
        />
      </FormContainer>
      <FormContainer>
        <FormLabel>{FORM_MID_TEXT.company[1].title}</FormLabel>
        <FormInput
          placeholder={FORM_MID_TEXT.company[1].placeHolder}
          {...register('companyName')}
        />
      </FormContainer>
      <Address>
        <FormContainer>
          <FormLabel>{FORM_MID_TEXT.company[2].title}</FormLabel>
          <Controller
            name="companyAddress"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                onChange={field => {
                  setSelectedAddress(field.value);
                }}
                placeholder={FORM_MID_TEXT.company[2].placeHolder}
                options={COMPANY_ADDRESS}
                isSearchable={false}
                styles={selectBoxStyle}
              />
            )}
          />
        </FormContainer>
        <Spacing />
        <FormContainer>
          <FormLabel>{FORM_MID_TEXT.company[3].title}</FormLabel>
          <Controller
            name="companyDetailAddress"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                isSearchable={false}
                placeholder={FORM_MID_TEXT.company[3].placeHolder}
                options={COMPANY_DETAIL_ADDRESS?.[selectedAddress]}
                styles={selectBoxStyle}
                noOptionsMessage={() => ''}
              />
            )}
          />
        </FormContainer>
      </Address>
      <FormContainer>
        <FormLabel>{FORM_MID_TEXT.inCharge[0].title}</FormLabel>
        <FormInput placeholder={FORM_MID_TEXT.inCharge[0].placeHolder} {...register('name')} />
      </FormContainer>
      <FormContainer>
        <FormLabel>{FORM_MID_TEXT.inCharge[1].title}</FormLabel>
        <FormInput placeholder={FORM_MID_TEXT.inCharge[1].placeHolder} {...register('phone')} />
      </FormContainer>
      <FormContainer>
        <FormLabel>{FORM_MID_TEXT.inCharge[2].title}</FormLabel>
        <FormInput placeholder={FORM_MID_TEXT.inCharge[2].placeHolder} {...register('email')} />
      </FormContainer>
      <FormBottom>
        <ErrorMessage>{errors?.companyType?.message}</ErrorMessage>
        <BottomTop>
          <CheckBox onClick={checkBoxOnClick}>
            {isChecked ? (
              <Checked alt="checked" src={CHECKED} />
            ) : (
              <NotChecked alt="not_checked" src={NOT_CHECKED} />
            )}
          </CheckBox>
          <BottomTopText>{FORM_BOT_TEXT.title}</BottomTopText>
        </BottomTop>
        <BottomBottom>
          <ApplyButton type="submit" value={FORM_BOT_TEXT.btnText} />
        </BottomBottom>
      </FormBottom>
    </Form>
  );
}

const ErrorMessage = styled.div``;

const Spacing = styled.div`
  width: 5vw;
`;

const Form = styled.form`
  padding: 2vw;

  @media (max-width: 600px) {
    padding: 5vw;
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FormLabel = styled.label`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 0.9em;
  margin-bottom: 1vh;
`;
const FormInput = styled.input`
  margin: 0 0 2vh 0;
  padding-left: 10px;
  height: 50px;
  background: #efefef;
  border: none;
  border-radius: 4px;
  font-size: 0.9em;

  :focus {
    outline: none;
  }
`;
const FormSelect = styled.select`
  margin-bottom: 2vh;
  //padding: 2vh 0.6vw;
  //background: #efefef;
  //border: none;
  border: 1px solid;
  border-radius: 10px;
  font-size: 0.9em;

  & :invalid {
    color: #a00;
  }
`;
const FormOptionPlaceHolder = styled.option``;
const FormOption = styled.option``;
const Address = styled.div`
  display: flex;
  //flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const FormBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const BottomTop = styled.div`
  display: flex;
  align-items: center;
`;
const CheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5vw;
  cursor: pointer;

  @media (max-width: 600px) {
    margin-right: 1vw;
  }
`;

const Checked = styled.img`
  width: 20px;
`;
const NotChecked = styled.img`
  width: 20px;
`;

const BottomTopText = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';

  @media (max-width: 600px) {
    font-size: 0.85em;
  }
`;

const BottomBottom = styled.div`
  margin-top: 2vh;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ApplyButton = styled.input`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  background: #fbaf17;
  padding: 1vh 2vw;
  border: none;
  border-radius: 20px;
  color: #fff;

  @media (max-width: 600px) {
    width: 100%;
    font-size: 1em;
  }
`;
