import React from 'react';

import styled from 'styled-components';

import PutNewLine from '@common/PutNewLine';
import DOWNLOAD_ICON from '@svgs/download_icon.svg';
import DUMMY_APP_SCREEN from '@svgs/dummy_app_screen.svg';

interface Figure {
  title: string;
  count: number;
}

const DUMMY: Figure[] = [
  { title: '장소 확인 수', count: 11111110 },
  { title: '월 방문자 수', count: 0 },
  { title: 'SNS 팔로워 수', count: 0 },
  { title: '하루 평균 페이지 수', count: 0 },
];

const FIGURE_TEXT = {
  title: ['애기야가자', '앱 다운로드 수'],
};

export default function MainFigure(): JSX.Element {
  return (
    <MainFigureContainer>
      <MainFigureAtMobile>
        <AppScreen src={DUMMY_APP_SCREEN} alt="DUMMY_APP_SCREEN" />
        <div style={{ height: '40vh' }} />
        <DownloadTitle>
          <PutNewLine data={FIGURE_TEXT.title} textAlign="center" />
        </DownloadTitle>
        <DownloadCount>
          <DownloadIcon src={DOWNLOAD_ICON} alt={FIGURE_TEXT.title[1]} />
          <DownloadFigure>10000000000</DownloadFigure>
        </DownloadCount>
      </MainFigureAtMobile>
      <MainFigureLeftSide>
        {DUMMY.map((item, index) => {
          return (
            <EachFigureContainer key={`figure_${index}`}>
              <EachFigureIconContainer>icon</EachFigureIconContainer>
              <EachFigureTextContainer>
                <EachFigureText>{item.count.toLocaleString()}건</EachFigureText>
                <EachFigureTitle>{item.title}</EachFigureTitle>
              </EachFigureTextContainer>
            </EachFigureContainer>
          );
        })}
      </MainFigureLeftSide>
      <MainFigureRightSide>
        <AppScreen src={DUMMY_APP_SCREEN} alt="DUMMY_APP_SCREEN" />
        <DownloadTitle>
          <PutNewLine data={FIGURE_TEXT.title} textAlign="right" />
        </DownloadTitle>
        <DownloadCount>
          <DownloadIcon src={DOWNLOAD_ICON} alt={FIGURE_TEXT.title[1]} />
          <DownloadFigure>10000000000</DownloadFigure>
        </DownloadCount>
      </MainFigureRightSide>
    </MainFigureContainer>
  );
}

const MainFigureAtMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //position: absolute;
    //top: 0;
  }
`;

const AppScreen = styled.img`
  //position: absolute;
  //top: -100vh;
  //right: 0;
  width: 30vw;
  margin-bottom: 5vh;
  //width: 80%;

  @media (max-width: 600px) {
    width: 60vw;
    position: absolute;
    top: 25vh;
  }
`;

const MainFigureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5vh 10vw;

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
const MainFigureLeftSide = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const MainFigureRightSide = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;
  right: 10vw;

  @media (max-width: 600px) {
    display: none;
  }
`;
const EachFigureContainer = styled.div`
  margin: 3vh 3vw 0 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60vw;
    margin: 3vh 0;
  }
`;
const EachFigureIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #fbaf17;
  width: 5vw;
  height: 5vw;
  padding: 5vw;
`;

const EachFigureTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 35vw;
    align-items: flex-start;
  }
`;
const EachFigureText = styled.div`
  margin: 2vh 0;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 1.5em;

  @media (max-width: 600px) {
    margin: 1vh 0;
  }
`;
const EachFigureTitle = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 500;
  font-size: 1em;
`;

const DownloadTitle = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 2.5em;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

const DownloadCount = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2vh;
`;

const DownloadIcon = styled.img`
  width: 3.5vw;

  @media (max-width: 600px) {
    width: 2em;
  }
`;

const DownloadFigure = styled.div`
  margin-left: 1vw;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 3vw;
  text-align: right;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;
