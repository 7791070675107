import React from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AdjustCenter from '@common/AdjustCenter';
import ListPageTemplate from '@common/ListPageTemplate';

export interface IWrapper {
  headerHeight: string;
}

const DUMMY_NOTICE_LIST = [
  { id: 1, title: '테스트입니다1', createdAt: '2023-01-20', content: <p>이거됨?</p> },
  { id: 2, title: '테스트입니다2', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 3, title: '테스트입니다3', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 4, title: '테스트입니다4', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 5, title: '테스트입니다5', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 6, title: '테스트입니다6', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 7, title: '테스트입니다7', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 8, title: '테스트입니다8', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 9, title: '테스트입니다9', createdAt: '2023-01-20', content: '히히히힣' },
  { id: 10, title: '테스트입니다10', createdAt: '2023-01-20', content: '히히히힣' },
];

const NOTICE_DETAIL_TEXT = {
  topTitle: '공지사항',
  topCount: 'total: ',
  contentDate: '작성일자',
  button: '목록',
};

export default function NoticeDetail() {
  const navigate = useNavigate();
  let PATH_PARAMS: any = window.location.pathname.split('/')[2];

  const goToListOnClick = () => {
    navigate(-1);
  };

  return (
    <ListPageTemplate title="공지사항" count={0}>
      <AdjustCenter>
        <NoticeDetailBottom>
          <TitleWrapper>
            <Title>{DUMMY_NOTICE_LIST[PATH_PARAMS - 1].title}</Title>
            <DateWrapper>
              <Text>{NOTICE_DETAIL_TEXT.contentDate}</Text>
              <Date>YYYY.MM.DD</Date>
            </DateWrapper>
          </TitleWrapper>
          <ContentWrapper>
            <Content>{DUMMY_NOTICE_LIST[PATH_PARAMS - 1].content}</Content>
          </ContentWrapper>
          <ButtonWrapper>
            <GoToList onClick={goToListOnClick}>{NOTICE_DETAIL_TEXT.button}</GoToList>
          </ButtonWrapper>
        </NoticeDetailBottom>
      </AdjustCenter>
    </ListPageTemplate>
  );
}

const NoticeDetailBottom = styled.div`
  margin-bottom: 10vh;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vh 3.5vw;
  border-top: 1px solid #fbaf14;
`;

const Title = styled.div`
  font-size: 1.5em;
  font-family: 'GmarketSansMedium', sans-serif;

  @media (max-width: 600px) {
    font-size: 1em;
  }
`;
const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;
const Text = styled.div``;
const Date = styled.div``;
const ContentWrapper = styled.div`
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 3vh 3.5vw;
`;
const Content = styled.div``;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const GoToList = styled.button`
  border: none;
  background: #fbaf14;
  border-radius: 10px;
  padding: 1vh 1.5vw;
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  margin: 2vh 3vw 2vh 0;

  @media (max-width: 600px) {
    font-size: 0.8em;
    padding: 1vh 3.5vw;
  }
`;
