import React, { ReactNode } from 'react';

import styled from 'styled-components';

export interface ChildProps {
  children: ReactNode;
}

export default function AdjustCenter({ children }: ChildProps) {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 80%;
`;
