import React from 'react';

import styled from 'styled-components';

import MainInfo from '@components/main/MainInfo';
import MainNotice from '@components/main/MainNotice';
import MainFigure from '@components/main/MainFigure';
import MainProcess from '@components/main/MainProcess';
import MainAppDown from '@components/main/MainAppDown';
import MainTopBanner from '@components/main/MainTopBanner';
import MainMidBanner from '@components/main/MainMidBanner';

import { HEADER_HEIGHT } from '@const/index';

const BUTTON_TEXT = '입점 및 광고문의';

interface IMainWrapper {
  headerHeight: string;
}

interface IProps {
  isOpen: boolean;
  onClick: () => void;
}
export default function Main({ isOpen, onClick }: IProps): JSX.Element {
  return (
    <MainWrapper headerHeight={HEADER_HEIGHT}>
      <MainTopBanner onClick={onClick} />
      <MainFigure />
      <MainMidBanner />
      <MainProcess />
      <MainNotice />
      <MainInfo />
      <MainAppDown />
      {!isOpen && <FixedButton onClick={onClick}>{BUTTON_TEXT}</FixedButton>}
    </MainWrapper>
  );
}
const MainWrapper = styled.div<IMainWrapper>`
  position: relative;
  margin-top: ${({ headerHeight }) => headerHeight};
`;

const FixedButton = styled.div`
  cursor: pointer;
  position: fixed;
  border-radius: 60px;
  bottom: 2.5vh;
  right: 2.5vw;
  padding: 1.5vh 1.5vw;
  background: #fbaf17;
  color: #fff;
  font-weight: 700;
  z-index: 100;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
`;
