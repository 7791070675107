import React from 'react';

import styled from 'styled-components';

import PutNewLine from '@common/PutNewLine';
import AdjustCenter from '@common/AdjustCenter';
import BABYGO_LOGO from '@svgs/babygo_logo.svg';

const APP_DOWN_TEXT = {
  topTitle: '애기야가자 사장님앱',
  midContent: [
    '애기야가자 사장님 전용 앱을 다운 받고',
    '고객분들에게 가장 빠르게 매장 소식을 전해주세요.',
  ],
  bottomTitle: '앱 다운로드',
  bottomButtonText: '사장님앱 다운로드',
};
export default function MainAppDown() {
  return (
    <AdjustCenter>
      <Container>
        <LeftContainer>
          <LeftTop>{APP_DOWN_TEXT.topTitle}</LeftTop>
          <LeftMiddle>
            <PutNewLine data={APP_DOWN_TEXT.midContent} />
          </LeftMiddle>
          <LeftBottom>
            <LeftBottomLeft>
              <LeftBottomTitle>{APP_DOWN_TEXT.bottomTitle}</LeftBottomTitle>
              <LeftBottomLine />
              <LeftBottomButton>
                <LeftBottomButtonLogo src={BABYGO_LOGO} />
                {APP_DOWN_TEXT.bottomButtonText}
              </LeftBottomButton>
            </LeftBottomLeft>
            <QRCode />
          </LeftBottom>
        </LeftContainer>
      </Container>
    </AdjustCenter>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;

  margin: 10vh 0;
`;

const LeftContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  padding: 3vw;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
    padding: 5vw 3vw;
  }
`;
const LeftTop = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 2vh;

  @media (max-width: 600px) {
    font-size: 1.8em;
  }
`;
const LeftMiddle = styled.div`
  font-size: 0.9em;
  margin-bottom: 2vh;

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;
const LeftBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftBottomLeft = styled.div`
  width: 70%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const QRCode = styled.img`
  width: 10vw;
  height: 10vw;

  @media (max-width: 600px) {
    display: none;
  }
`;

const LeftBottomTitle = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 1.2em;
`;

const LeftBottomLine = styled.div`
  border: 0.5px solid #fbaf17;
  margin: 1.5vh 0;
`;

const LeftBottomButton = styled.button`
  display: flex;
  align-items: center;
  padding: 2vh 2vw;
  border: none;
  border-radius: 60px;
  background: #fbaf17;
  color: #fff;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 1.2em;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
  }
`;

const LeftBottomButtonLogo = styled.img`
  width: 2vw;
  margin-right: 1vw;

  @media (max-width: 600px) {
    width: 5vw;
  }
`;
