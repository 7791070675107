import React from 'react';

import styled from 'styled-components';

import PutNewLine from '@common/PutNewLine';

const BANNER_TEXT = {
  title: ['애기야가자와 함께 할', '사장님을 찾습니다.'],
  inquire: ['입점 및 광고 문의'],
};

interface IProps {
  onClick: () => void;
}
export default function MainTopBanner({ onClick }: IProps): JSX.Element {
  return (
    <BannerContainer>
      <BannerLeftSideContainer>
        <BannerTitle>
          <PutNewLine data={BANNER_TEXT.title} />
        </BannerTitle>
        <BannerInquireButton onClick={onClick}>{BANNER_TEXT.inquire}</BannerInquireButton>
      </BannerLeftSideContainer>
    </BannerContainer>
  );
}

const BannerContainer = styled.div`
  background-color: #fbaf17;
  height: 300px;
  padding: 20vh 10vw;

  @media (max-width: 600px) {
    padding: 5vh 5vw;
  }
`;
const BannerLeftSideContainer = styled.div``;
const BannerRightSideContainer = styled.div``;
const BannerTitle = styled.h1`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 3em;
  color: #fff;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;
const BannerInquireButton = styled.button`
  border: none;
  border-radius: 20px;
  padding: 1.5vh 1.5vw;
  background: #fff;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
  font-size: 1em;

  @media (max-width: 600px) {
    display: none;
  }
`;
