import React from 'react';

import styled from 'styled-components';

import PutNewLine from '@common/PutNewLine';
import AdjustCenter from '@common/AdjustCenter';
import MID_BANNER_IMG from '@svgs/middle_banner_image.svg';
import MID_BOTTOM_BANNER_IMG from '@svgs/middle_bottom_banner_image.svg';
import MID_BOTTOM_BANNER_IMG_MOBILE from '@svgs/middle_bottom_banner_image_for_mobile.svg';

const BANNER_TEXT = [
  {
    title: ['앱에서 우리 매장의', '리뷰 확인 후 답글까지'],
    subTitle: '우리 매장에 댓글을 직접 관리할 수 있어요',
  },
  {
    title: ['내 사업장 관리를', '간단한 클릭으로'],
    subTitle: '메인화면에서 내 사업장을 등록 / 수정 할 수 있어요',
  },
  {
    title: ['매장의 새로운 소식을', '고객에게 전해보세요'],
    subTitle: '내 매장을 찜한 고객에게만 알람을 보낼 수 있어요',
  },
  {
    title: ['프로모션 및 이벤트를', '진행하실 생각이라면!'],
    subTitle: '판매를 위한 다양한 프로모션이 마련되어있습니다',
  },
];

const MID_BANNER_TEXT = ['단골고객 만들기', '애기야가자와 함께'];

export default function MainMidBanner(): JSX.Element {
  return (
    <MidBannerWrapper>
      <TopContainer>
        <MidBannerText>
          <PutNewLine data={MID_BANNER_TEXT} />
        </MidBannerText>
        <MidBannerIMG src={MID_BANNER_IMG} />
        <MidBannerIMGForMobile src={MID_BOTTOM_BANNER_IMG_MOBILE} />
      </TopContainer>
      <BottomContainer>
        <AdjustCenter>
          <MidBottomBannerIMG src={MID_BOTTOM_BANNER_IMG} />
        </AdjustCenter>
      </BottomContainer>
    </MidBannerWrapper>
  );
}

const MidBottomBannerIMG = styled.img`
  width: 100%;
`;

const TopContainer = styled.div`
  //width: 100%;
  padding: 0 5vw;
  height: 600px;
  background: #efefef;
  display: flex;
  position: relative;
  //padding: 0;
  margin: 0;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;
const MidBannerText = styled.div`
  font-family: 'GmarketSansMedium', sans-serif;
  color: #fbaf14;
  font-weight: 700;
  font-size: 3.5em;
  z-index: 99;
  margin: 5% 0 0 5%;

  @media (max-width: 1025px) {
    margin: 5% 0 0 0;
  }

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

const MidBannerIMG = styled.img`
  position: absolute;
  height: 100%;
  right: 10%;
  bottom: 0;

  @media (max-width: 1025px) {
    right: 2%;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const MidBannerIMGForMobile = styled.img`
  display: none;

  @media (max-width: 600px) {
    display: block;
    margin-left: 2vw;
  }
`;

const BottomContainer = styled.div`
  margin: 10vh 0;
`;

const MidBannerWrapper = styled.div`
  width: 100%;
`;
const MidBannerContainer = styled.div`
  background: #efefef;
  width: 80%;
  padding: 30vh 0;
`;
const MidBannerTitle = styled.div``;
const MidBannerSubTitle = styled.div``;
