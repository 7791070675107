import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import CALL from '@svgs/call.svg';
import MAIL from '@svgs/mail.svg';
import LOCATION from '@svgs/location.svg';
import APP_DOWN_IMG from '@svgs/app_down.svg';

export default function Footer() {
  const [isMain, setIsMain] = useState(false);

  const location = useLocation();

  useEffect(() => {
    let URL = location.pathname;

    if (URL === '/') setIsMain(true);
    else setIsMain(false);
  }, [location]);

  const SECOND_CONTENTS = [
    {
      title: '부산본사: 부산광역시 해운대구 센텀중앙로 97 A동 1110호',
      icon: <Icon src={LOCATION} />,
    },
    {
      title: '서울지사: 서울특별시 성동구 성수이로22번길 37, 3층 307B (성수 아크밸리)',
      icon: <Icon src={LOCATION} />,
    },
    {
      title: '제주지사 : 제주특별자치도 제주시 첨단로 330 A동 2층 S-220호 (영평동, 세미양빌딩)',
      icon: <Icon src={LOCATION} />,
    },
    {
      title: `대표메일: sos@babygo.kr`,
      icon: <Icon src={MAIL} />,
    },
    {
      title: '대표전화: 1877-8739',
      icon: <Icon src={CALL} />,
    },
  ];

  return (
    <FooterContainer>
      {isMain && (
        <RightContainer>
          <AppDownIMG alt="앱 다운로드 이미지" src={APP_DOWN_IMG} />
        </RightContainer>
      )}
      <FooterContent>
        <First>
          <FirstLeftSide>(주) 애기야가자</FirstLeftSide>
          <FirstCenter>|</FirstCenter>
          <FirstRightSide> 애기야가자 홈페이지</FirstRightSide>
        </First>
        <Second>
          {SECOND_CONTENTS.map((content, index) => {
            return (
              <SecondContentsWrapper key={`second_${index}`}>
                {content.icon}
                <SecondTitle>{content.title}</SecondTitle>
              </SecondContentsWrapper>
            );
          })}
        </Second>
      </FooterContent>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  background: #2f2f30;
  //height: 20vh;
  color: #fff;
  display: flex;
  //justify-content: center;
  //align-items: center;
  position: relative;
  z-index: 99;
`;

const RightContainer = styled.div`
  position: absolute;
  top: -32.3vw;
  right: 8vw;

  @media (max-width: 600px) {
    display: none;
  }
`;
const AppDownIMG = styled.img`
  object-fit: contain;
  width: 25vw;
  z-index: 99;
`;

const FooterContent = styled.div`
  padding: 4vh 4vw;
  font-family: 'GmarketSansMedium', sans-serif;

  @media (max-width: 600px) {
    font-size: 0.75em;
  }
`;
const First = styled.div`
  display: flex;
`;
const FirstLeftSide = styled.div``;
const FirstCenter = styled.div`
  margin: 0 1vw;
`;
const FirstRightSide = styled.div``;
const Second = styled.div``;

const SecondContentsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SecondTitle = styled.div`
  margin-left: 1vw;
`;
const Icon = styled.img``;
