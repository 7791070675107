import React, { useState } from 'react';

import styled from 'styled-components';

import AdjustCenter from '@common/AdjustCenter';
import Pagination from '@components/notice/Pagination';
import ListPageTemplate from '@common/ListPageTemplate';

const DUMMY = [
  {
    id: 1,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 2,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 3,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 4,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 5,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 6,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 7,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 8,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
  {
    id: 9,
    imageURL: 'image/놀자에요.png',
    title: '놀자숲',
    content: '애기야가자 놀자숲',
  },
];
export default function Info(): JSX.Element {
  const [page, setPage] = useState(1);

  const LIMIT_PER_PAGE = 9;
  const OFFSET = 5;
  const TOTAL = DUMMY.length;

  return (
    <ListPageTemplate title="오늘의 업체 소식 " count={0}>
      <AdjustCenter>
        <ListGridWrapper>
          {DUMMY.map((item, key) => {
            return (
              <GridItem key={`grid_item_${key}`}>
                <ItemImage>
                  <IMG src={item.imageURL} alt="dd" />
                </ItemImage>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemContent>{item.content}</ItemContent>
              </GridItem>
            );
          })}
        </ListGridWrapper>
      </AdjustCenter>
      <Pagination
        LIMIT_PER_PAGE={LIMIT_PER_PAGE}
        OFFSET={OFFSET}
        TOTAL={TOTAL}
        page={page}
        setPage={setPage}
      />
    </ListPageTemplate>
  );
}

const ListGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1vw;
  margin: 0 0 10vh 0;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ItemImage = styled.div`
  height: 25vw;
  width: 25vw;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 75vw;
    height: 75vw;
  }
`;

const IMG = styled.img`
  height: 25vw;
  width: 25vw;

  @media (max-width: 600px) {
    width: 75vw;
    height: 75vw;
  }
`;
const ItemTitle = styled.div`
  margin: 2vh 0;
  width: 90%;
  font-size: 1.4em;
`;
const ItemContent = styled.div`
  margin: 2vh 0;
  width: 90%;
  font-size: 1em;
`;
