import React, { useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AdjustCenter from '@common/AdjustCenter';
import Pagination from '@components/notice/Pagination';
import ListPageTemplate from '@common/ListPageTemplate';

interface IWrapper {
  headerHeight: string;
}

const DUMMY_NOTICE_LIST = [
  { id: 1, title: '테스트입니다1', createdAt: '2023-01-20' },
  { id: 2, title: '테스트입니다2', createdAt: '2023-01-20' },
  { id: 3, title: '테스트입니다3', createdAt: '2023-01-20' },
  { id: 4, title: '테스트입니다4', createdAt: '2023-01-20' },
  { id: 5, title: '테스트입니다5', createdAt: '2023-01-20' },
  { id: 6, title: '테스트입니다6', createdAt: '2023-01-20' },
  { id: 7, title: '테스트입니다7', createdAt: '2023-01-20' },
  { id: 8, title: '테스트입니다8', createdAt: '2023-01-20' },
  { id: 9, title: '테스트입니다9', createdAt: '2023-01-20' },
  { id: 10, title: '테스트입니다10', createdAt: '2023-01-20' },
];

for (let i = 0; i < 120; i++) {
  DUMMY_NOTICE_LIST.push({
    id: DUMMY_NOTICE_LIST[DUMMY_NOTICE_LIST.length - 1].id + 1,
    title: 'TEST' + i,
    createdAt: DUMMY_NOTICE_LIST[DUMMY_NOTICE_LIST.length - 1].createdAt,
  });
}

export default function Notice(): JSX.Element {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const LIMIT_PER_PAGE = 10;
  const OFFSET = 5;

  const TOTAL = DUMMY_NOTICE_LIST.length;

  return (
    <ListPageTemplate title="공지사항" count={TOTAL}>
      <AdjustCenter>
        <NoticeListWrapper>
          <NoticeHeader>
            <NoticeId>번호</NoticeId>
            <NoticeHeaderTitle>제목</NoticeHeaderTitle>
            <NoticeCreatedAt>작성일자</NoticeCreatedAt>
          </NoticeHeader>
          <NoticeList>
            {DUMMY_NOTICE_LIST.map((notice, index) => {
              return (
                <NoticeItem
                  key={`notice_${index}`}
                  onClick={() => {
                    console.log(notice.id);
                    navigate(`/notice/${notice.id}`);
                  }}
                >
                  <NoticeId>{notice.id}</NoticeId>
                  <NoticeTitle>{notice.title}</NoticeTitle>
                  <NoticeCreatedAt>{notice.createdAt}</NoticeCreatedAt>
                </NoticeItem>
              );
            })}
          </NoticeList>
        </NoticeListWrapper>
      </AdjustCenter>
      <Pagination
        LIMIT_PER_PAGE={LIMIT_PER_PAGE}
        OFFSET={OFFSET}
        TOTAL={TOTAL}
        page={page}
        setPage={setPage}
      />
    </ListPageTemplate>
  );
}

const Wrapper = styled.div<IWrapper>`
  margin-top: ${({ headerHeight }) => headerHeight};
`;
const Banner = styled.div`
  width: 100%;
  height: 400px;
  background: #fbaf14;
`;
const ContentWrapper = styled.div``;
const NoticeListTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10vh 0;
  padding: 0 2vw;

  @media (max-width: 600px) {
    margin: 5vh 0;
  }
`;

const NoticeListTitle = styled.div`
  border-left: 5px solid #fbaf14;
  padding-left: 1vw;
  font-family: 'GmarketSansMedium', sans-serif;
  font-weight: 700;
  font-size: 2em;

  @media (max-width: 600px) {
    font-size: 1.8em;
    padding-left: 3vw;
  }
`;
const Count = styled.div``;
const NoticeListWrapper = styled.div`
  margin-bottom: 10vh;
`;
const NoticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fbaf14;
  border-bottom: 1px solid #bbb;
`;
const NoticeId = styled.div`
  width: 10%;
  text-align: center;
  padding: 2vh 1vw;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 20%;
  }
`;
const NoticeTitle = styled.div`
  width: 70%;
  text-align: center;
  padding: 2vh 1vw;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

const NoticeHeaderTitle = styled(NoticeTitle)`
  justify-content: center;
`;

const NoticeCreatedAt = styled.div`
  width: 20%;
  text-align: center;
  padding: 2vh 1vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    display: none;
  }
`;
const NoticeList = styled.div``;
const NoticeItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;
`;
