import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import PutNewLine from '@common/PutNewLine';
import CLOSE_ICON from '@svgs//close_icon.svg';

const FORM_TOP_TEXT = {
  title: '입점 및 광고신청서',
  content: [
    '입점에 필요한 정보를 입력 후, 제출해주세요.',
    '애기야가자 제휴 담당자가 확인 후, 빠르게 연락드리겠습니다.',
  ],
};

interface IProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function ApplyFormTop({ setIsOpen }: IProps) {
  const closeButtonHandler = () => {
    setIsOpen(prev => !prev);
    document.body.style.overflow = 'unset';
  };

  return (
    <FormTop>
      <TopTitle>
        <TopTitleText>{FORM_TOP_TEXT.title}</TopTitleText>
        <TopCloseButton alt="close" src={CLOSE_ICON} onClick={closeButtonHandler} />
      </TopTitle>
      <TopContentText>
        <PutNewLine data={FORM_TOP_TEXT.content} />
      </TopContentText>
    </FormTop>
  );
}

const FormTop = styled.div`
  background: #fbaf17;
  padding: 2vw;

  @media (max-width: 600px) {
    padding: 5vw;
  }
`;
const TopTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const TopTitleText = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  color: #fff;
  font-weight: 700;
  font-size: 1.6em;
  margin-bottom: 2vh;

  @media (max-width: 600px) {
    font-size: 1.4em;
  }
`;
const TopCloseButton = styled.img`
  width: 16px;
  cursor: pointer;
`;
const TopContentText = styled.div`
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;
