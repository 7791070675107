import styled, { css, keyframes } from 'styled-components';
import ModalPortal from '@common/ModalPortal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NAV_BTN_ARR = ['공지사항', '소개서 다운로드', '입점 문의', '스토어 어드민 페이지'];
interface IProp {
  isHamburgerOpen: boolean;
  setIsHamburgerOpen: Dispatch<SetStateAction<boolean>>;
  hamburgerOnClick: () => void;
  onClick: () => void;
}

interface IAniProp {
  isAnimate: boolean;
}
export default function HamburgerModal({
  isHamburgerOpen,
  setIsHamburgerOpen,
  hamburgerOnClick,
  onClick,
}: IProp) {
  const navigate = useNavigate();
  const [isAnimate, setIsAnimate] = useState(isHamburgerOpen);
  const [localVisible, setLocalVisible] = useState(isHamburgerOpen);

  const closeHamburgerModal = (index: number) => {
    setIsAnimate(false);
    setTimeout(() => setIsHamburgerOpen(false), 500);
    if (index !== 2) document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    if (!localVisible) {
      closeHamburgerModal(0);
    }
  }, [localVisible]);

  const menuOnClickHandler = (index: number) => {
    switch (index) {
      case 0:
        navigate('notice');
        closeHamburgerModal(index);
        return;
      case 1:
        return;
      case 2:
        onClick();
        closeHamburgerModal(index);
        return;
      case 3:
        return;
    }
  };

  return (
    <HamburgerModalPortal>
      <Backdrop
        isAnimate={isAnimate}
        onClick={() => {
          setLocalVisible(prev => !prev);
        }}
      >
        <ModalContainer onClick={e => e.stopPropagation()} isAnimate={isAnimate}>
          <ModalContent>
            <Close
              onClick={() => {
                setLocalVisible(prev => !prev);
              }}
            >
              X
            </Close>
            <MenuOptions>
              {NAV_BTN_ARR.map((option, index) => {
                return (
                  <OptionBox key={`nav_opt_${index}`} onClick={() => menuOnClickHandler(index)}>
                    {option}
                  </OptionBox>
                );
              })}
            </MenuOptions>
          </ModalContent>
        </ModalContainer>
      </Backdrop>
    </HamburgerModalPortal>
  );
}

const translateOff = keyframes`
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(100%);
  }
  
`;
const translateOn = keyframes`
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const HamburgerModalPortal = styled(ModalPortal)``;
const Backdrop = styled.div<IAniProp>`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  z-index: 101;

  animation: ${({ isAnimate }) =>
    isAnimate
      ? css`
          ${fadeIn} 0.5s ease-in-out
        `
      : css`
          ${fadeOut} 0.5s ease-in-out
        `};
  -webkit-animation: ${({ isAnimate }) =>
    isAnimate
      ? css`
          ${fadeIn} 0.5s ease-in-out
        `
      : css`
          ${fadeOut} 0.5s ease-in-out
        `};
`;
const ModalContainer = styled.div<IAniProp>`
  background: #fff;
  height: 100%;
  width: 80%;
  border-radius: 20px 0 0 20px;
  z-index: 102;

  animation: ${({ isAnimate }) =>
    isAnimate
      ? css`
          ${translateOn} 0.5s ease-in-out
        `
      : css`
          ${translateOff} 0.5s ease-in-out
        `};
  -webkit-animation: ${({ isAnimate }) =>
    isAnimate
      ? css`
          ${translateOn} 0.5s ease-in-out
        `
      : css`
          ${translateOff} 0.5s ease-in-out
        `};
`;

const ModalContent = styled.div`
  margin: 10vw;

  display: flex;
  flex-direction: column;
`;
const Close = styled.div`
  text-align: right;

  font-weight: 700;
  font-size: 2em;
`;
const MenuOptions = styled.div``;
const OptionBox = styled.div`
  font-family: 'GMarketSansMedium', sans-serif;
  font-weight: 700;
  font-size: 1.4em;
  margin: 5vh 0;
`;
