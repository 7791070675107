import React from 'react';

import styled from 'styled-components';

import PutNewLine from '@common/PutNewLine';

const TITLE = '애기야가자와 함께하기 : 입점 방법';

const PROCESS_TEXT = [
  {
    title: '입점 문의사항 작성',
    subTitle: ['입점 및 광고 문의를 선택하신 후', '입점 신청서를 작성해 제출해주세요'],
  },
  {
    title: '잠시만 기다려주세요',
    subTitle: ['애기야가자 담당자가 확인 후', '연락을 드립니다.'],
  },
  {
    title: '애기야가자와 함께 시작!',
    subTitle: ['아무거나아무거나'],
  },
];
export default function MainProcess(): JSX.Element {
  return (
    <Container>
      <Title>{TITLE}</Title>
      <ItemContainer>
        {PROCESS_TEXT.map((item, index) => {
          return (
            <EachItem key={`process_${index}`}>
              <ContentStep>{`STEP ${index + 1}`}</ContentStep>
              <ContentTitle>{item.title}</ContentTitle>
              <ContentSubTitle>
                <PutNewLine data={item.subTitle} textAlign="center" />
              </ContentSubTitle>
            </EachItem>
          );
        })}
      </ItemContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10vh 0;
  align-items: center;
  background: #f8f8f8;
`;
const ItemContainer = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const EachItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1vw;
`;
const Title = styled.div`
  text-align: left;
  width: 80%;
  margin-bottom: 5vh;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-size: 2em;
  font-weight: 700;
`;
const ContentStep = styled.div`
  background: #fbaf17;
  border-radius: 60px;
  padding: 1vh 2vw;
  color: #fff;
  margin-bottom: 3vh;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;

  @media (max-width: 600px) {
    margin-top: 3vh;
  }
`;
const ContentTitle = styled.div`
  margin-bottom: 3vh;
  font-family: 'GmarketSansMedium', sans-serif, 'Apple SD Gothic Neo';
  font-weight: 700;
`;
const ContentSubTitle = styled.div``;
